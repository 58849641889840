import { compact, concat, each, isEmpty } from "lodash"
import zxcvbn from "zxcvbn"

const $ = window.jQuery
const selector = "[data-plugin=strong-password]"
const validateStrongPasswords = () => {
  $(selector).keyup((event) => {
    const val = $(event.currentTarget).val()
    const result = zxcvbn(val)
    const meter = document.getElementById("password-strength-meter")
    const text = document.getElementById("password-strength-text")
    const hints = document.getElementById("password-strength-hints")
    const strength = {
      0: "Too Weak",
      1: "Too Weak",
      2: "Still Too Weak",
      3: "Almost There",
      4: "Strong",
    }

    let hintHTML = ""

    meter.value = result.score
    meter.style.display = "block"

    text.innerHTML = `<p>Password Strength: <strong>${strength[result.score]}</strong></p>`

    if (!isEmpty(result.feedback.warning) || !isEmpty(result.feedback.suggestions)) {
      const textItems = compact(concat([result.feedback.warning], result.feedback.suggestions)).map(
        (message) => message.replace(/\.?$/, "."),
      )

      hintHTML += "<p>Hint:</p>"
      hintHTML += "<ul>"
      each(textItems, (item) => {
        hintHTML += `<li>${item}</li>`
      })
      hintHTML += "</ul>"
      hints.style.display = "block"
    } else {
      hints.style.display = "none"
    }

    hints.innerHTML = hintHTML
  })
}

$.onmount(selector, validateStrongPasswords)
